<template>
  <div>
    <div class="justify-center max-w-md px-5 pt-5">
      <div class="grid grid-cols-1 gap-6">
        <div class="text-xl font-bold send-title">Send Money</div>
        <form @submit.prevent="sendMoney" v-if="!sentOff">
          <label class="block">
            <span class="text-sm font-light text-gray-700">Wallet Account</span>
            <input
              type="text"
              v-model="form.account_number"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm  account-number-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder=""
            />
          </label>
          <label class="block mt-5">
            <span class="text-sm font-light text-gray-700">Amount</span>
            <input
              type="text"
              v-model="form.amount"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm  amount-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder=""
            />
          </label>
          <div class="fixed inset-x-0 bottom-0 px-5 mb-5">
            <div>
              <button
                class="w-full px-4 py-2 font-semibold text-white bg-green-500 border border-green-500 rounded  hover:bg-green-500 hover:text-white hover:border-transparent"
              >
                SEND
              </button>
            </div>
            <div class="mt-5">
              <button
                @click="$router.back()"
                class="w-full px-4 py-2 font-semibold text-white bg-red-500 border border-red-500 rounded  hover:bg-red-700 hover:text-white hover:border-transparent"
              >
                BACK
              </button>
            </div>
          </div>
        </form>
        <div v-else class="mt-5">
          <div class="text-base font-light send-summary-title">Summary</div>
          <div v-if="!isError">
            <div class="text-base font-light send-summary-sub-title">
              Money Sent Successfully
            </div>
            <div
              class="mt-5 text-lg font-light tracking-wider  send-summary-current-balance"
            >
              Current Balance: ${{ Number(balance).toLocaleString() }}
            </div>
          </div>
          <div v-else>
            <div class="text-lg font-light send-summary-sub-title">
              Error Occurred
            </div>
          </div>
          <div class="fixed inset-x-0 bottom-0 px-5 mb-5">
            <button
              @click="$router.back()"
              class="w-full px-4 py-2 font-semibold text-white bg-red-500 border border-red-500 rounded  hover:bg-red-700 hover:text-white hover:border-transparent"
            >
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      sentOff: false,
      isError: false,
      balance: 0,
      form: {
        account_number: "",
        amount: "",
      },
    };
  },
  methods: {
    ...mapActions(["SendMoney"]),
    async sendMoney() {
      const DataForm = new FormData();
      DataForm.append("account_number", this.form.account_number);
      DataForm.append("amount", this.form.amount);
      DataForm.append("tt", 1);
      try {
        this.sentOff = true;
        this.balance = await this.SendMoney(DataForm);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style scoped>
</style>
