<template>
  <div>
    <a-form-model @submit.prevent="submit" :layout="'vertical'">
      <div class="max-w-md px-5">
        <div class="grid grid-cols-1 mt-5">
          <div class="text-xl font-bold login-title">SIGN-IN</div>
          <a-form-model-item
            :label="'Phone Number'"
            class="mt-10 text-center"
            :validate-status="_.anyErrors('msisdn', errors)"
            :help="_.getErrorMessage('msisdn', errors)"
          >
            <input
              type="text"
              v-model="form.msisdn"
              class="ant-input msisdn-input"
              placeholder=""
            />
          </a-form-model-item>
          <a-form-model-item
            :label="'PIN'"
            class="text-center"
            :validate-status="_.anyErrors('pin', errors)"
            :help="_.getErrorMessage('pin', errors)"
          >
            <a-input-password
              v-model="form.pin"
              class="pin-input"
              placeholder=""
            />
          </a-form-model-item>
          <div class="fixed inset-x-0 bottom-0 px-5 mb-5 submit">
            <button
              class="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded  hover:bg-blue-700"
            >
              LOGIN
            </button>
            <a-button
              type="link"
              class="mt-3"
              @click="$router.push('/register')"
            >
              SIGN-UP
            </a-button>
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      errors: [],
      form: {
        msisdn: "",
        pin: "",
      },
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("msisdn", this.form.msisdn);
      User.append("pin", this.form.pin);
      try {
        await this.LogIn(User);
        this.$router.push("/");
        this.showError = false;
      } catch (err) {
        console.log(err);
        let error = this._.getError(err);
        console.log(error);
        if (error.is_error_bag) {
          this.errors = error.content;
        } else {
          this.$message.error(error);
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
