<template>
  <div>
    <div class="flex flex-col max-w-md px-5 mt-5">
      <div class="text-xl font-bold home-title">Welcome</div>
      <div class="grid flex-1 grid-cols-1 gap-6 mt-5">
        <button
          @click="goToCheckBalance"
          class="px-4 py-2 font-semibold text-white bg-blue-500 border border-blue-500 rounded  hover:bg-blue-700 hover:text-white hover:border-transparent"
        >
          Check Balance
        </button>
        <button
          @click="goToSendMoney"
          class="px-4 py-2 font-semibold text-white bg-blue-500 border border-blue-500 rounded  hover:bg-blue-700 hover:text-white hover:border-transparent"
        >
          Send Money
        </button>
        <button
          @click="goToRequestMoney"
          class="px-4 py-2 font-semibold text-white bg-blue-500 border border-blue-500 rounded  hover:bg-blue-700 hover:text-white hover:border-transparent"
        >
          Request Money
        </button>
        <button
          @click="goToTransactions"
          class="px-4 py-2 font-semibold text-white bg-blue-500 border border-blue-500 rounded  hover:bg-blue-700 hover:text-white hover:border-transparent"
        >
          Transactions
        </button>
      </div>
      <div class="fixed inset-x-0 bottom-0 px-5 mb-5">
        <button
          @click="logout"
          class="w-full px-4 py-2 font-semibold text-white bg-red-500 border border-red-500 rounded  hover:bg-red-700 hover:text-white hover:border-transparent"
        >
          EXIT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToCheckBalance() {
      this.$router.push("/balance");
    },
    goToSendMoney() {
      this.$router.push("/send");
    },
    goToRequestMoney() {
      this.$router.push("/request");
    },
    goToTransactions() {
      this.$router.push("/transactions");
    },
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
</style>
