<template>
  <div>
    <div class="max-w-md px-5 pt-5">
      <div class="grid grid-cols-1 gap-6">
        <div class="text-xl font-bold balance-title">Balance</div>
        <div>
          <div class="text-lg font-light tracking-wider balance-amount">
            ${{ Number(balance).toLocaleString() }}
          </div>
        </div>
      </div>
    </div>
    <div class="fixed inset-x-0 bottom-0 px-5 mb-5 submit">
      <button
        @click="$router.back()"
        class="w-full px-4 py-2 font-semibold text-white bg-red-500 border border-red-500 rounded  hover:bg-red-700 hover:text-white hover:border-transparent"
      >
        BACK
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      balance: 0,
    };
  },
  methods: {
    ...mapActions(["GetBalance"]),
  },
  async mounted() {
    try {
      this.balance = await this.GetBalance();

      //this.showError = false;
    } catch (error) {
      //this.showError = true;
    }
  },
};
</script>

<style scoped>
.balance-amount {
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
