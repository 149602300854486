/* eslint-disable no-unused-vars */
const state = {
  token: null,
  info: {
    msisdn: null,
  },
};

const getters = {
  token: (state) => state.token,
  msisdn: (state) => state.info.msisdn,
  pin: (state) => state.info.pin,
  isAuthenticated: (state) => !!state.token,
};

const actions = {
  async LogIn({ commit }, User) {
    const response = await window.axios.post("login", User);
    response.data["content"]["msisdn"] = User.get("msisdn");
    await commit("setUser", response.data);
  },

  async SignUp({ commit }, User) {
    const response = await window.axios.post("register", User);
    return response.data.content.pin;
  },

  async GetBalance({ commit, state }) {
    const response = await window.axios.get("wallets", {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    return response.data.content.balance;
  },

  async GetHistory({ commit, state }, data) {
    const response = await window.axios.get(
      `wallets/history?page=${data.current_page}&search=${data.search_value}&category=${data.category}`,
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    );
    return response.data.transactions;
  },

  async SendMoney({ commit, state }, SendForm) {
    const response = await window.axios.post("wallets/transactions", SendForm, {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    return response.data.content.balance;
  },

  async RequestMoney({ commit, state }, RequestForm) {
    const response = await window.axios.post("wallets/request", RequestForm, {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
  },

  async LogOut({ commit }) {
    const user = null;
    commit("LogOut", user);
  },
};

const mutations = {
  setUser(state, response) {
    state.token = response.content.access_token;
    state.info.msisdn = response.content.msisdn;
  },

  LogOut(state) {
    state.info.msisdn = null;
    state.token = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
