<template>
  <div>
    <a-modal
      v-model="visible"
      title="PIN"
      :closable="false"
      @cancel="handleOk"
      @ok="handleOk"
    >
      <p>The pin is the last 4 digits of your phone number.</p>
    </a-modal>
    <a-form-model
      @submit.prevent="submit"
      :layout="'vertical'"
      class="vertical-auto-i"
    >
      <div class="max-w-md px-5">
        <div class="grid grid-cols-1 mt-5">
          <div class="text-xl font-bold login-title">SIGN-UP</div>
          <a-form-model-item
            :label="'First Name'"
            class="text-center"
            :validate-status="_.anyErrors('first_name', errors)"
            :help="_.getErrorMessage('first_name', errors)"
          >
            <a-input
              type="text"
              v-model="form.first_name"
              class="ant-input first-input"
              placeholder=""
            />
          </a-form-model-item>
          <a-form-model-item
            :label="'Last Name'"
            class="text-center"
            :validate-status="_.anyErrors('last_name', errors)"
            :help="_.getErrorMessage('last_name', errors)"
          >
            <a-input
              type="text"
              v-model="form.last_name"
              class="ant-input last-input"
              placeholder=""
            />
          </a-form-model-item>
          <a-form-model-item
            :label="'Email'"
            class="text-center"
            :validate-status="_.anyErrors('email', errors)"
            :help="_.getErrorMessage('email', errors)"
          >
            <a-input
              type="email"
              v-model="form.email"
              class="ant-input email-input"
              placeholder=""
            />
          </a-form-model-item>
          <a-form-model-item
            :label="'Phone Number'"
            class="text-center"
            :validate-status="_.anyErrors('msisdn', errors)"
            :help="_.getErrorMessage('msisdn', errors)"
          >
            <InputMask
              class="ant-input msisdn-input"
              v-model="form.msisdn"
              :mask="['(###) ###-####']"
              placeholder=""
            />
          </a-form-model-item>
          <a-form-model-item
            :label="'Date of Birth'"
            class="text-center"
            :validate-status="_.anyErrors('dob', errors)"
            :help="_.getErrorMessage('dob', errors)"
          >
            <a-date-picker
              @change="dobChange"
              class="block dob-input"
              placeholder=""
            />
          </a-form-model-item>
          <a-form-model-item
            :label="'TRN'"
            class="text-center"
            :validate-status="_.anyErrors('trn', errors)"
            :help="_.getErrorMessage('trn', errors)"
          >
            <InputMask
              class="ant-input trn-input"
              v-model="form.trn"
              :mask="['###-###-###']"
            />
          </a-form-model-item>
          <div class="fixed inset-x-0 bottom-0 px-5 mb-5 submit">
            <button
              class="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded  hover:bg-blue-700"
            >
              REGISTER
            </button>
            <a-button type="link" class="mt-3" @click="$router.push('/login')">
              SIGN-IN
            </a-button>
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { TheMask } from "vue-the-mask";

export default {
  name: "Register",
  components: {
    InputMask: TheMask,
  },
  data() {
    return {
      errors: [],
      pin: "",
      visible: false,
      form: {
        msisdn: "",
        first_name: "",
        last_name: "",
        email: "",
        trn: "",
        dob: null,
      },
    };
  },
  methods: {
    ...mapActions(["SignUp"]),
    dobChange(date, dateString) {
      this.form.dob = dateString;
    },
    showModal() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
      this.$router.push("/login");
    },
    async submit() {
      const User = new FormData();
      User.append("msisdn", this.form.msisdn);
      User.append("first_name", this.form.first_name);
      User.append("last_name", this.form.last_name);
      User.append("email", this.form.email);
      User.append("trn", this.form.trn);
      User.append("dob", this.form.dob);
      try {
        this.pin = await this.SignUp(User);
        this.showModal();
        this.showError = false;
      } catch (err) {
        let error = this._.getError(err);
        if (error.is_error_bag) {
          this.errors = error.content;
        } else {
          this.$message.error(error);
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
